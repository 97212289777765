import React from "react";
import { SEO } from "~components/templates/seo";
import { PipeDriveForm } from "~components/utils/pipedrive-form";
import * as styles from "./index.module.scss";
import { SectionLayout } from "~components/templates/section-layout";

const KENRO_CONTACT_FORM_ID =
  "1yMYyVx7LsYOTYkmCqZuVQyStUs0Ls6mkB9068Bl3wR7pTi7Ho6I2rYyrkOALTswb";

const Component: React.FC = () => {
  return (
    <>
      <SEO meta={[{ name: "robots", content: "noindex" }]} />
      <div className={styles.KenroContact}>
        <SectionLayout>
          <PipeDriveForm formID={KENRO_CONTACT_FORM_ID} />
        </SectionLayout>
      </div>
    </>
  );
};

export default Component;
